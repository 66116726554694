<template>
  <div>
    <v-card>
      <v-card-title>
        <h4>Donation List</h4>
        <template v-if="donor"> - {{donor.full_name}} ({{donor.mobile}})</template>
        <v-spacer/>
        <v-btn outlined small class="ml-2" @click="download('pdf')"><v-icon>{{ icons.mdiDownload }}</v-icon>Export PDF</v-btn>
        <v-btn outlined small class="ml-2" @click="download('xls')"><v-icon>{{ icons.mdiDownload }}</v-icon>Export Excel</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
            <v-text-field
              dense
              outlined
              clearable
              hide-details
              label="Search"
              :prepend-inner-icon="icons.mdiMagnify"
              class="app-bar-search flex-grow-0"
              :value="selectedFilters.search"
              @input="debounceSearch"
            />
          </v-col>
          <v-col v-if="$store.state.me && $store.state.me.role === 'admin'" cols="12" :md="$store.state.me.role === 'admin' ? 3 : 4">
            <v-select
              outlined
              dense
              chips
              deletable-chips
              clearable
              multiple
              hide-details
              label="Temples"
              placeholder="Temples"
              item-text="name"
              item-value="id"
              :items="templeOptions"
              :value="selectedFilters.temples"
              @change="updateFilter($event,'temples')"
            />
          </v-col>
          <v-col cols="12" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
            <v-select
              outlined
              dense
              chips
              deletable-chips
              clearable
              multiple
              hide-details
              label="Donation Categories"
              placeholder="Donation Categories"
              item-text="name"
              item-value="id"
              :items="donationTypeOptions"
              :value="selectedFilters.donation_types"
              @change="updateFilter($event,'donation_types')"
            />
          </v-col>
          <v-col cols="12" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
            <v-select
              outlined
              dense
              chips
              deletable-chips
              clearable
              multiple
              hide-details
              label="Festivals"
              placeholder="Festivals"
              item-text="name"
              item-value="id"
              :items="festivalOptions"
              :value="selectedFilters.festivals"
              @change="updateFilter($event,'festivals')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="donations"
              :expanded.sync="expanded"
              disable-sort
              show-expand
              :hide-default-footer="!donations.length"
              :items-per-page="15"
              :page="pagination.page"
              :server-items-length="pagination.total"
              :footer-props="{
                'items-per-page-options': [15],
                'items-per-page-text': '',
              }"
              @update:page="handleUpdatePage"
              :loading="loading"
            >
              <template v-slot:item.name="{item}">
                <span v-if="!item.haste">{{item.name}}</span>
                <v-menu v-else offset-y open-on-hover :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{item.name}} <v-chip x-small>+{{item.haste.split(/\r?\n/).length}}</v-chip></span>
                  </template>
                  <v-list>
                    <v-list-item v-for="(hasteName, index) in item.haste.split(/\r?\n/)" :key="index">
                      <v-list-item-title>{{ hasteName }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.amount="{item}">
                <span>₹{{item.amount}}</span>
              </template>
              <template v-slot:item.remaining="{item}">
                <span>₹{{item.remaining}}</span>
                <v-menu v-if="item.reminders.length" bottom left offset-y open-on-hover :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      v-if="item.remaining"
                      :content="item.reminders.length"
                      :value="item.reminders.length"
                      color="error"
                      overlap
                      offset-x="3"
                      offset-y="5"
                    >
                      <v-btn small icon class="me-n3 mt-n1" @click="confirmSendReminder(item.id)" v-bind="attrs" v-on="on"><v-icon>{{ icons.mdiBellRingOutline }}</v-icon></v-btn>
                    </v-badge>
                  </template>
                  <v-list>
                    <v-list-item v-for="reminder in item.reminders" :key="reminder.id">
                      <v-list-item-title>{{reminder.created_at | moment('DD/MM/YYYY h:mma')}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn v-else-if="item.remaining" small icon class="me-n3 mt-n1" @click="confirmSendReminder(item.id)"><v-icon>{{ icons.mdiBellRingOutline }}</v-icon></v-btn>
              </template>
              <template v-slot:item.date="{item}">
                <span>{{item.date | moment('DD/MM/YYYY')}}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on">
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="$emit('editing-donation', donations.find(donation => donation.id === item.id))">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="confirmDonationDeletion(item.id)" class="error">
                      <v-list-item-title class="white--text">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:expanded-item="{headers, item}">
                <td :colspan="headers.length">
                  <v-data-table
                    :items="item.installments"
                    :headers="[
                      {text: 'Location', value: 'location.name'},
                      {text: 'Receiver', value: 'receiver_name'},
                      {text: 'Amount', value: 'amount', align: 'end'},
                      {text: 'Date', value: 'created_at', align: 'end'},
                      {text: 'Actions', value: 'action', align: 'end'},
                    ]"
                    disable-sort
                    hide-default-footer
                  >
                    <template v-slot:item.amount="{item}">
                      <span>₹{{item.amount}}</span>
                    </template>
                    <template v-slot:item.created_at="{item}">
                      <span>{{item.created_at | moment('DD/MM/YYYY hh:mma')}}</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on">
                            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="$emit('editing-installment', item)">
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="confirmInstallmentDeletion(item.id)" class="error">
                            <v-list-item-title class="white--text">Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog persistent max-width="400" :value="deleteDonationConfirmationOpen">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this record?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="deleteDonationConfirmationOpen = false">No</v-btn>
          <v-btn color="primary" @click="deleteDonation">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="400" :value="deleteInstallmentConfirmationOpen">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this record?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="deleteInstallmentConfirmationOpen = false">No</v-btn>
          <v-btn color="primary" @click="deleteInstallment">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="300" :value="remindConfirmationOpen">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text>Are you sure you want to send the reminder?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="remindConfirmationOpen = false">No</v-btn>
          <v-btn color="primary" @click="remindDonation">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

  import {mdiMagnify, mdiDotsVertical, mdiBellRingOutline, mdiDownload} from '@mdi/js';
  import _ from 'lodash';
  import download from 'downloadjs';

  export default {
    data() {
      return {
        headers: [
          {text: 'Receipt Number', value: 'id'},
          {text: 'Temple', value: 'temple.name'},
          {text: 'Donation Category', value: 'donation_type.name'},
          {text: 'Celebration', value: 'celebration.name'},
          {text: 'Name', value: 'name'},
          {text: 'Amount', value: 'amount', align: 'end'},
          {text: 'Remaining', value: 'remaining', align: 'end'},
          {text: 'Date', value: 'date', align: 'end'},
          {text: 'Actions', value: 'action', align: 'end'},
        ],
        pagination: {
          page: 1,
          total: 0,
        },
        queryParams: {
          page: 1,
          search: null,
          temples: [],
          festivals: [],
          donation_types: [],
        },
        donations: [],
        expanded: [],
        templeOptions: [],
        festivalOptions: [],
        donationTypeOptions: [],
        deletingDonationID: null,
        deletingInstallmentID: null,
        remindingDonationID: null,
        deleteDonationConfirmationOpen: false,
        deleteInstallmentConfirmationOpen: false,
        remindConfirmationOpen: false,
        loading: false,
        snackbar: {
          active: false,
          message: null,
          timeout: -1,
          color: null
        },
        icons: {
          mdiMagnify,
          mdiDotsVertical,
          mdiBellRingOutline,
          mdiDownload,
        },
      };
    },
    props: {
      donor: {type: Object, default: null},
    },
    computed: {
      selectedFilters() {

        return  {
          search: this.queryParams.search,
          temples: this.queryParams.temples,
          festivals: this.queryParams.festivals,
          donation_types: this.queryParams.donation_types,
        };
      }
    },
    watch: {
      queryParams: {
        deep: true,
        async handler() {

          this.$router.replace({query: this.queryParams});
          await this.fetchDonations(this.queryParams);
        }
      },
      deleteDonationConfirmationOpen() {

        if (this.deleteDonationConfirmationOpen === false) this.deletingDonationID = null;
      },
      deleteInstallmentConfirmationOpen() {

        if (this.deleteInstallmentConfirmationOpen === false) this.deletingInstallmentID = null;
      },
      remindConfirmationOpen() {

        if (this.remindConfirmationOpen === false) this.remindingDonationID = null;
      }
    },
    created() {

      this.loading = true;

      axiosBackend.get('/options/donation-types')
        .then(async (response) => {

          this.donationTypeOptions = response.data.donation_types;

          axiosBackend.get('/options/festivals')
            .then(async (response) => {

              this.festivalOptions = response.data.festivals;

              axiosBackend.get('/options/temples')
                .then(async (response) => {

                  this.templeOptions = response.data.temples;

                  if (Object.entries(this.$router.currentRoute.query).length > 0) {

                    this.queryParams = {
                      page: this.$router.currentRoute.query.page && this.$router.currentRoute.query.page !== 'undefined' ? parseInt(this.$router.currentRoute.query.page) : 1,
                      search: this.$router.currentRoute.query.search && this.$router.currentRoute.query.search !== 'undefined' ? this.$router.currentRoute.query.search : null,
                      temples: this.$router.currentRoute.query.temples ? (Array.isArray(this.$router.currentRoute.query.temples) ? this.$router.currentRoute.query.temples.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.temples)]) : [],
                      festivals: this.$router.currentRoute.query.festivals ? (Array.isArray(this.$router.currentRoute.query.festivals) ? this.$router.currentRoute.query.festivals.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.festivals)]) : [],
                      donation_types: this.$router.currentRoute.query.donation_types ? (Array.isArray(this.$router.currentRoute.query.donation_types) ? this.$router.currentRoute.query.donation_types.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.donation_types)]) : [],
                    };
                  } else {

                    this.fetchDonations(this.queryParams);
                  }
                });
            });
        });
    },
    methods: {
      fetchDonations(params) {

        this.loading = true;

        if (this.donor) params.donor = this.donor.id;
        axiosBackend.get('/donations', {params})
          .then(response => {

            this.donations = response.data.donations;
            this.pagination.total = response.data.meta.total;
            this.pagination.page = response.data.meta.current_page;
            this.loading = false;
          });
      },
      download(type) {

        axiosBackend.get('/donations', {params: {...{download: type}, ...this.queryParams}, responseType: 'blob'})
          .then(response => {

            let filename = `donations-${this.$moment().format('YYYYMMDDHHmmss')}.${type}`;
            let disposition = response.headers['content-disposition'];

            if (disposition) {

              let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              let matches = filenameRegex.exec(disposition);
              if (matches !== null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            download(response.data, filename, response.headers['content-type']);
          });
      },
      deleteDonation() {

        this.loading = true;
        axiosBackend.delete('/donations/' + this.deletingDonationID)
          .then(() => {

            this.fetchDonations(this.queryParams);
            this.deleteDonationConfirmationOpen = false;
            this.loading = false;
          });
      },
      deleteInstallment() {

        this.loading = true;
        axiosBackend.delete('/installments/' + this.deletingInstallmentID)
          .then(() => {

            this.fetchDonations(this.queryParams);
            this.deleteInstallmentConfirmationOpen = false;
            this.loading = false;
          })
          .catch((error) => {

            this.snackbar = {
              active: true,
              color: 'error',
              timeout: 5000,
              message: error.response.data.message,
            };
            this.deleteInstallmentConfirmationOpen = false;
            this.loading = false;
          });
      },
      remindDonation() {

        this.loading = true;
        axiosBackend.post('/donations/' + this.remindingDonationID + '/reminders')
          .then(() => {

            this.fetchDonations(this.queryParams);
            this.remindConfirmationOpen = false;
            this.loading = false;
          });
      },
      debounceSearch: _.debounce(function (value) {

        this.updateFilter(value, 'search');
      }, 500),
      updateFilter(value, field) {

        this.queryParams[field] = value;
        this.queryParams.page = 1;
      },
      handleUpdatePage(page) {

        this.queryParams.page = page;
      },
      confirmDonationDeletion(id) {

        this.deletingDonationID = id;
        this.deleteDonationConfirmationOpen = true;
      },
      confirmSendReminder(id) {

        this.remindingDonationID = id;
        this.remindConfirmationOpen = true;
      },
      confirmInstallmentDeletion(id) {

        this.deletingInstallmentID = id;
        this.deleteInstallmentConfirmationOpen = true;
      },
    }
  }
</script>

<style scoped>

</style>
