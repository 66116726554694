<template>
  <v-dialog max-width="800" :value="show">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn dark icon @click="$emit('update:show', false)">
          <v-icon class="font-weight-bold">{{icons.mdiClose}}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 white--text">Edit Donor</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-card-title/>
      <v-card-text class="pt-3">
        <v-form>
          <v-row>
            <v-col md="6">
              <v-text-field
                type="number"
                outlined
                dense
                label="Contact Number"
                placeholder="Contact Number"
                v-model="form.mobile"
                :error-messages="errors.mobile"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                label="Name"
                placeholder="Name"
                v-model="form.name"
                :error-messages="errors.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                label="Address 1"
                placeholder="Address 1"
                v-model="form.address_1"
                :error-messages="errors.address_1"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                label="Address 2"
                placeholder="Address 2"
                v-model="form.address_2"
                :error-messages="errors.address_2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3">
              <v-select
                outlined
                dense
                label="Country"
                placeholder="Country"
                item-text="name"
                item-value="iso2"
                :items="countryOptions"
                v-model="form.country"
                :error-messages="errors.country"
              />
            </v-col>
            <v-col md="3">
              <v-select
                outlined
                dense
                label="State"
                placeholder="State"
                item-text="name"
                item-value="iso2"
                :items="stateOptions"
                v-model="form.state"
                :error-messages="errors.state"
              />
            </v-col>
            <v-col md="3">
              <v-combobox
                outlined
                dense
                label="City/Village"
                placeholder="City/Village"
                :items="cityOptions"
                v-model="form.village_city"
                :error-messages="errors.village_city"
              />
            </v-col>
            <v-col md="3">
              <v-text-field
                outlined
                dense
                label="PIN Code"
                placeholder="PIN Code"
                v-model="form.pincode"
                :error-messages="errors.pincode"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-combobox
                outlined
                dense
                label="Native City/Village"
                placeholder="Native City/Village"
                :items="nativeOptions"
                v-model="form.native"
                :error-messages="errors.native"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                label="PAN Number"
                placeholder="PAN Number"
                v-model="form.pan_number"
                :error-messages="errors.pan_number"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="secondary" @click="$emit('update:show', false)">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>

  import {mdiClose} from '@mdi/js';

  let donorSchema = {
    id: null,
    mobile: null,
    name: null,
    address_1: null,
    address_2: null,
    pincode: null,
    village_city: null,
    state: null,
    country: null,
    native: null,
    current: null,
    pan_number: null,
  };

  export default {
    data() {
      return {
        form: {...donorSchema},
        errors: {...donorSchema},
        snackbar: {
          active: false,
          message: null,
          timeout: -1,
          color: null
        },
        countryOptions: [],
        stateOptions: [],
        cityOptions: [],
        nativeOptions: [],
        loading: false,
        icons: {
          mdiClose,
        },
      };
    },
    props: {
      show: false,
      donor: null,
    },
    watch: {
      show() {

        if (this.show && this.donor) {

          this.form = (({id, mobile, name, address_1, address_2, pincode, country, state, village_city, current, native, pan_number}) => ({id, mobile, name, address_1, address_2, pincode, country, state, village_city, current, native, pan_number}))(this.donor);
        } else if (!this.show) {

          this.form = {...donorSchema};
        }
      },
      'form.country'(newVal) {

        this.updateStateOptions(newVal);
      },
      'form.state'(newVal) {

        this.updateCityOptions(this.form.country, newVal);
      },
    },
    created() {

      axiosInstanceCSC.get('/countries/IN/cities')
        .then(response => {

          this.nativeOptions = response.data.map(item => item.name).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));

          axiosInstanceCSC.get('/countries')
            .then(response => {

              this.countryOptions = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            });
        });
    },
    methods: {
      save() {

        this.loading = true;
        axiosBackend.post('donors/' + this.form.id, this.form)
          .then(response => {

            this.$emit('update:show', false);
          })
          .catch((error) => {

            if (error.response.status === 422) this.errors = error.response.data.errors;

            this.snackbar = {
              active: true,
              color: 'error',
              timeout: 5000,
              message: error.response.data.message,
            };
          });
      },
      updateStateOptions(ciso) {

        return new Promise((resolve, reject) => {

          axiosInstanceCSC.get(`/countries/${ciso}/states`)
            .then(response => {

              this.stateOptions = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
              resolve();
            }).catch(error => {
            reject();
          });
        });
      },
      updateCityOptions(ciso, siso) {

        return new Promise((resolve, reject) => {

          axiosInstanceCSC.get(`/countries/${ciso}/states/${siso}/cities`)
            .then(response => {

              this.cityOptions = response.data.map(item => item.name).sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
              if (!this.cityOptions.includes(this.form.village_city)) this.form.village_city = null;
              resolve();
            }).catch(error => {
            reject();
          });
        });
      },
    }
  }
</script>

<style scoped>

</style>
