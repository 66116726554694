<template>
  <div>
    <donor-form :show.sync="donorFormStatus" :donor="editingDonor"></donor-form>
    <v-dialog max-width="100%" v-model="donationListDialogStatus">
      <donation-list v-if="activeDonor" ref="donations" :donor="activeDonor" @editing-donation="editDonation" @editing-installment="editInstallment"></donation-list>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title><h4>Donors</h4></v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="12">
                <v-row>
                  <v-col md="12">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      hide-details
                      label="Search"
                      :prepend-inner-icon="icons.mdiMagnify"
                      class="app-bar-search flex-grow-0"
                      :value="selectedFilters.search"
                      @input="debounceSearch"
                    />
                  </v-col>
                  <v-col md="4" class="text-right" v-if="false">
                    <v-btn outlined class="mr-2"><v-icon>{{ icons.mdiDownload }}</v-icon>Export PDF</v-btn>
                    <v-btn outlined><v-icon>{{ icons.mdiDownload }}</v-icon>Export Excel</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="$store.state.me && $store.state.me.role === 'admin'" :md="$store.state.me.role === 'admin' ? 4 : 6">
                    <v-select
                      outlined
                      dense
                      chips
                      deletable-chips
                      clearable
                      multiple
                      hide-details
                      label="Temples"
                      placeholder="Temples"
                      item-text="name"
                      item-value="id"
                      :items="templeOptions"
                      :value="selectedFilters.temples"
                      @change="updateFilter($event,'temples')"
                    />
                  </v-col>
                  <v-col :md="$store.state.me && $store.state.me.role === 'admin' ? 4 : 6">
                    <v-select
                      outlined
                      dense
                      chips
                      deletable-chips
                      clearable
                      multiple
                      hide-details
                      label="Festivals"
                      placeholder="Festivals"
                      item-text="name"
                      item-value="id"
                      :items="festivalOptions"
                      :value="selectedFilters.festivals"
                      @change="updateFilter($event,'festivals')"
                    />
                  </v-col>
                  <v-col :md="$store.state.me && $store.state.me.role === 'admin' ? 4 : 6">
                    <v-select
                      outlined
                      dense
                      chips
                      deletable-chips
                      clearable
                      multiple
                      hide-details
                      label="Donation Categories"
                      placeholder="Donation Categories"
                      item-text="name"
                      item-value="id"
                      :items="donationTypeOptions"
                      :value="selectedFilters.donation_types"
                      @change="updateFilter($event,'donation_types')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :headers="headers"
                  :items="donors"
                  disable-sort
                  :hide-default-footer="!donors.length"
                  :items-per-page="15"
                  :page="pagination.page"
                  :server-items-length="pagination.total"
                  :footer-props="{
                    'items-per-page-options': [15],
                    'items-per-page-text': '',
                  }"
                  @update:page="handleUpdatePage"
                  :loading="loading"
                >
                  <template v-slot:item.donations_sum_amount="{item}">
                    <span>₹{{item.donations_sum_amount}}</span>
                  </template>
                  <template v-slot:item.donations_sum_remaining="{item}">
                    <span>₹{{item.donations_sum_remaining}}</span>
                  </template>
                  <template v-slot:item.last_donation.created_at="{item}">
                    <span>{{item.last_donation.created_at | moment('DD/MM/YYYY')}}</span>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on"><v-icon>{{ icons.mdiDotsVertical }}</v-icon></v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="activeDonor = donors.find(donor => donor.id === item.id)">
                          <v-list-item-title>Donation List</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editDonor(item.id)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import { mdiMagnify, mdiDownload, mdiDotsVertical } from '@mdi/js';
  import DonorForm from "@/components/donor/Form";
  import DonationList from "@/components/donation/List";
  import _ from 'lodash';

  export default {
    data() {
      return {
        donors: [],
        queryParams: {
          page: 1,
          search: null,
        },
        pagination: {
          page: 1,
          total: 0,
        },
        headers: [
          {text: 'Name', value: 'name'},
          {text: 'Contact Number', value: 'mobile'},
          {text: 'Total', value: 'donations_sum_amount'},
          {text: 'Remaining', value: 'donations_sum_remaining'},
          {text: 'Last Donation Date', value: 'last_donation.created_at'},
          {text: 'Actions', value: 'action', align: 'end'},
        ],
        templeOptions: [],
        celebrationOptions: [],
        festivalOptions: [],
        donationTypeOptions: [],
        activeDonor: null,
        editingDonor: null,
        donorFormStatus: false,
        loading: false,
        icons: {
          mdiMagnify,
          mdiDownload,
          mdiDotsVertical,
        },
      };
    },
    computed: {
      selectedFilters() {

        return {
          search: this.queryParams.search,
          temples: this.queryParams.temples,
          festivals: this.queryParams.festivals,
          celebrations: this.queryParams.celebrations,
          donation_types: this.queryParams.donation_types,
        };
      },
      donationListDialogStatus: {
        get() {
          return this.activeDonor !== null;
        },
        set() {
          this.activeDonor = null;
        }
      }
    },
    watch: {
      queryParams: {
        deep: true,
        async handler() {
          if (this.$router.currentRoute.fullPath !== this.$router.resolve({name: this.$router.currentRoute.name, query: this.queryParams}).route.fullPath) {
            this.$router.replace({query: this.queryParams});
          }
          await this.fetchDonors(this.queryParams);
        }
      },
      donorFormStatus() {
        if (this.donorFormStatus === false) {
          this.fetchDonors(this.queryParams);
          this.editingDonor = null;
        }
      },
    },
    components: {
      DonorForm,
      DonationList,
    },
    created() {
      this.loading = true;
      axiosBackend.get('/options/donation-types').then(async (response) => {
        this.donationTypeOptions = response.data.donation_types;
        axiosBackend.get('/options/festivals').then(async (response) => {
          this.festivalOptions = response.data.festivals;
          axiosBackend.get('/options/temples').then(async (response) => {
            this.templeOptions = response.data.temples;
            if (Object.entries(this.$router.currentRoute.query).length > 0) {
              this.queryParams = {
                page: this.$router.currentRoute.query.page && this.$router.currentRoute.query.page !== 'undefined' ? parseInt(this.$router.currentRoute.query.page) : 1,
                search: this.$router.currentRoute.query.search && this.$router.currentRoute.query.search !== 'undefined' ? this.$router.currentRoute.query.search : null,
                temples: this.$router.currentRoute.query.temples ? (Array.isArray(this.$router.currentRoute.query.temples) ? this.$router.currentRoute.query.temples.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.temples)]) : [],
                festivals: this.$router.currentRoute.query.festivals ? (Array.isArray(this.$router.currentRoute.query.festivals) ? this.$router.currentRoute.query.festivals.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.festivals)]) : [],
                celebrations: this.$router.currentRoute.query.celebrations ? (Array.isArray(this.$router.currentRoute.query.celebrations) ? this.$router.currentRoute.query.celebrations.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.celebrations)]) : [],
                donation_types: this.$router.currentRoute.query.donation_types ? (Array.isArray(this.$router.currentRoute.query.donation_types) ? this.$router.currentRoute.query.donation_types.map(item => parseInt(item)) : [parseInt(this.$router.currentRoute.query.donation_types)]) : [],
              };
            } else {
              this.fetchDonors(this.queryParams);
            }
          });
        });
      });
    },
    methods: {
      fetchDonors(params) {
        this.loading = true;
        axiosBackend.get('/donors', {params})
          .then(response => {
            this.donors = response.data.donors;
            this.pagination.total = response.data.meta.total;
            this.pagination.page = response.data.meta.current_page;
            this.loading = false;
          });
      },
      debounceSearch: _.debounce(function (value) {
        this.updateFilter(value, 'search');
      }, 500),
      updateFilter(value, field) {
        this.queryParams[field] = value;
        this.queryParams.page = 1;
        this.fetchDonors(this.queryParams);
      },
      handleUpdatePage(page) {
        this.queryParams.page = page;
      },
      editDonation(donation) {

        this.$router.push({name: 'edit-donation', params: {id: donation.id}});
      },
      editInstallment(installment) {

        this.$router.push({name: 'edit-installment', params: {id: installment.id}});
      },
      editDonor(id) {

        this.editingDonor = this.donors.find(item => item.id === id);
        this.donorFormStatus = true;
      },
    }
  }
</script>
